
  .gridClass{
    border-bottom:1px solid rgba(0,0,0,.12);
  padding: 0 0 20px 20px;
  }
  .toggleExpand{
    cursor: pointer;
    margin-left: auto; 
    color: black;
    margin-right: 5px;
  }
  .topPick{
    background-color: var(--color-green) !important;
    display: flex;
    margin-top: 0px;
  }
  .supplyValue{
    color: var(--color-dimmergray);
    font-weight: 600 ;
    margin-left: 8px;
  }
  .supplyImage{
    width: 80% !important;
    height: 80% !important;
    margin-right: 30px !important;
  }
  .loaderMargin {
    margin-top: 50px;
}
  .equipmentName{
    font-size: var(--secondary-heading) !important;
    font-weight: 600 !important;
    margin-bottom: 5px !important;
    margin-top:0px !important;
    margin-right: 5px; 
    
  }
  .equipmentImage{
    flex-basis: 8.3333% !important;
  }
  .content{
    color: var(--color-dimgray) !important;
    font-weight: 600 !important;
  }  
  .value{
    color: var(--color-dimmergray) !important;
    font-weight: 600 !important;
  }
  .myCustomChip {
    color: white !important;
    margin: 0px 5px 10px 5px !important;
    padding: 2px !important;
    border-radius: 6px !important;
    font-weight: 800 !important;
    font-size: var(--badges-font-size) !important;

  }
  
  .chips {
    display: flex;
    align-items: center;
    margin-top: 6px;
  }

  .customButtonContainer1 {
    display: flex;
    align-items: flex-end !important;
    justify-content: flex-end !important;
    flex-wrap: wrap;
    flex: 1;
    margin-top: 10px;
    margin-right: 5px;
  }
  
  .tags {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  
  .customLink {
    margin-left: 8px;
    color: var(--color-base);
    font-weight: 600 !important;
  }

  .paddingTop20px{
   padding-top:20px
  }
  .paddingLeft20px{
    padding-left:20px
   }
  .marginLeft20px{
    margin-left: 20px;
  }
 
  .customBox2 {
    text-align: left;
    margin-bottom: 30px;
  }
   
 .gridDirection {
    display: flex !important;
    flex-direction: row !important;
    margin-top: 20px;
    align-items: baseline;
    margin-right: 16px;
  }
  
  .customSizeImage {
    height: 40px !important;
    min-width: 27px !important;
  }
  .clipboardStyle {
    height: 27px !important;
    min-width: 27px !important;
  }
  
  .cardContainer {
    padding: 0px !important;
  }
  .scopedClassHeading {
    color: var(--color-black);
    height: 32px !important;
    font-weight: 600 !important;
    font-size: var(--details-heading) !important;
    display: flex;
    align-items: center;
    background: var(--color-whitesmoke-100);
    width: 100%;
  }
  .scopedClassName {
    background: var(--color-whitesmoke-100);
    height: 52px;
    padding: 10px !important;
    color: var(--color-dimgray);
    font-weight: 600;
  }
  
  .marginLeft8px{
    margin-left: 8px;
  }
  
  @media (max-width: 480px) {
    .scopedClassName {
      background: var(--color-whitesmoke-100);
      height: 80px !important;
      font-family: var(--base-text);
      color: var(--color-dimgray);
      font-weight: 600px;
      display: flex !important;
      align-items: center !important;
    }
  
    .dataMargin {
      border-bottom:1px solid rgba(0,0,0,.12);
    }
  
    .tags {
      align-items: flex-start;
      flex-direction: column;
    }
    .equipmentName{
      margin-right: 0px;
    }
    .cardContainer {
      padding: 16px 0px 16px 16px !important;
    }
  
    .customButtonContainer1 {
      display: flex;
      align-items: flex-end !important;
      justify-content: flex-end !important;
      flex-wrap: wrap;
      flex: 1;
      margin-top: 10px;
      margin-right: 10px;
    }
    .chips {
      margin-top: 0px !important;
    }
  }
  
  @media screen and (max-width: 900px) {
    .cardContainer {
      padding: 0px !important;
    }
  
    .customButtonContainer1 {
      display: flex;
      align-items: flex-end !important;
      justify-content: flex-end !important;
      flex-wrap: wrap;
      flex: 1;
      margin-top: 10px;
      margin-right: 10px;
    }
    
  }
  @media screen and (max-width: 1200px) {
    .equipmentImage{
      flex-basis: 100% !important;
    }
  }
  @media (max-width: 768px) {
    .tags {
      align-items: flex-start;
      flex-direction: column;
    } 
    .equipmentName{
      margin-right: 0px;
    }
  }