.displayNone {
      display: none !important;
}

.displayBlock {
      display: block;
}

.imageLogo {
      width: 140px;
      height: 31px;
}

.marginBottomZero {
      margin-bottom: 0 !important;
}
.marginBottomFive {
      margin-bottom: 5px !important;
}
.marginBottomEight {
      margin-bottom: 8px !important;
}
.marginBottomTwenty {
      margin-bottom: 20px !important;
}

.marginVerticalThirty{
      margin: 30px 0px;
}

.marginVerticalTwenty{
      margin: 20px 0px;
}
.marginVerticalTen{
      margin: 10px 0px !important;
}
.marginTopZero {
      margin-top: 0px;
}
.marginTopEight {
      margin-top: 8px !important;
}
.marginTop10px{
      margin-top: 10px !important;
}
.marginTopThirty{
      margin-top: 30px !important;
}

.marginTopFive{
      margin-top: 5px !important;
}
.marginTopSix{
      margin-top: 6px !important;
}

.marginBottom10px{
      margin-bottom: 10px !important;
}
.marginBottomFive{
      margin-bottom: 5px !important;
}
.disabledBackGroundColor {
      background-color: var(--color-disabled);
}

.flexWrap{
      flex-wrap: wrap !important;
}

.marginZeroText {
      margin: 0;
      font-size: var(--copyright-text);
}
.marginZero {
      margin: 0 !important;
}

.marginRight10{
      margin-right: 10px !important;
}
.marginRight20{
      margin-right: 20px !important;
}
.marginRightEight{
      margin-right: 8px !important;
}
.marginRightBottom{
      margin-bottom: 8px !important;
}

.marginLeft10{
      margin-left: 10px !important;
}
.marginLeftEight{
      margin-left: 8px !important;
}
.marginLeftFive{
      margin-left: 5px;
}

.warningAlert {
      font-weight: 600;
      font-size: var(--common-heading);
}

.paddingZero {
      padding: 0 !important;
}
.paddingVertical_Five {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
}
.paddingHorizotal_Zero {
      padding-left: 0px !important;
      padding-right: 0px !important;
}
.padding20px {
      padding: 20px !important;
}
.paddingLeft20px {
      padding-left: 20px !important
}
.paddingFifteen {
      padding: 15px !important;
}
.paddingLeftFifteenpx {
      padding-left: 15px !important;
}
.paddingFive {
      padding: 5px !important;
}
.paddingLeft2px {
      padding-left: 2px;
}
.paddingBottomZero{
      padding-bottom: 0px;
}

.labelClass {
      font-family: var(--base-text);
      color: var(--color-dimgray);
      font-size: var(--common-heading);
      font-weight: 600;
      margin-bottom: 5px;
}

.valueClass {
      font-family: var(--base-text);
      color: var(--color-dimmergray);
      font-size: var(--common-heading);
      font-weight: 600;
      margin-bottom: 5px;
}

.mainContainer {
      padding-left: 10px;
}

.asteriskSignColor {
      color: var(--color-red);
}

.displayFlex {
      display: flex !important;
}

.alignItemCenter{
      align-items: center;
}

.flexDirectionColumn{
      flex-direction: column !important;
}

.flexJustifyBetween{
      display: flex;
      justify-content: space-between;
}

.fontWeight600 {
      font-weight: 600 !important;
}
.fontWeight700 {
      font-weight: 700;
}
.textDecoration {
      text-decoration: none !important;
}
.textDecorationUnderline {
      text-decoration: underline !important;
}
.textColorOrange {
      color:var(--color-orange);
}

.secoundaryHeading{
      font-size: var(--secondary-heading) !important;
}

.commonHeading{
      font-size: var(--common-heading) !important;
}

.colorBlack {
      color: var(--color-black) !important;
}

.cursorPointer {
      cursor: pointer !important;
}
.fontSizeThirteenPx {
      font-size: var(--badges-font-size);
}
.fontSizeTwentyFivePx {
      font-size: 25px !important;
}
.textAlignEnd{
      text-align: end;
}
.textAlignLeft{
      text-align: left;
}

.colorBlue {
      color: var(--color-base);
}

.priorityDescriptionMsg {
      margin-top: 10px;
      font-weight: 600;
      color: var(--color-dimgray);
}

.searchFilterIcon  {
      pointer-events: none;
      display: flex;
      align-items: center;
      color: var(--color-darkgray-100);
      justify-content: center;
}
  
.searchFilterIcon .filterIconInputBase  {
      padding-left: 5px;
      width: 100%;
      font-size: var(--common-content);
      text-align: center,
}

.height_fit_content {
      height:fit-content !important;
}

.width100per{
      width: 100%;
}

.maxWidthFortyEight{
      max-width: 48px !important;
}

.textInputColor{
      color: var(--text-input-color),
}

.flexNone{
      flex: none;
}

.justifyContentSpaceAround{
      justify-content: space-around !important;
}

.justifyContentCenter{
      justify-content: center !important;
}

.paddingVerticalAndHorizontal{
      padding: 14px 16px !important;
}

.textDimgray{
      color: var(--color-dimgray) !important;
}

.textDimmerGray{
      color: var(--color-dimmergray) !important;
}

.bgDarkGrey{
      background-color: var(--color--darkgrey) !important;
    }
.bgRed{
      background-color: var(--color-red) !important;
}
.bgOrange{
      background-color: var(--color-orange) !important;
}
.bgBaseColor{
      background-color: var(--color-base) !important;
}
.bgGreen{
      background-color: var(--color-green) !important;
}
.bgWhite{
      background-color: var(--bg-white) !important;
}

.detailsHeading{
      font-size: var(--details-heading) !important;
}
.disabledButton {
      background-color: var(--color-skyblue) !important;
      color: var(--bg-white) !important;
}

.hrMargin {
      border-color: rgba(0, 0, 0, 0.12) !important;
      border-bottom-width: thin !important;
      margin: 20px 0 !important;
}

.pageHeadingText {
      font-size: var(--main-heading);
      font-weight: var(--fontWeight600);
}
.locationText{
      font-weight: 600 !important;
      color: var(--color-dimmergray) !important;
      margin-left: 8px !important;
      font-size: var(--common-heading) !important;
}
.customStyleImages {
      height: 30px;
      width: 20px;
      margin-top:-16px !important;
}
@media (max-width: 388px){
      .customStyleImages {
            margin-top:-40px !important;
      }
}

.fontSize16px{
      font-size: var(--common-heading);
}

//Centralize button styling SSM-33662

//hover, size, transform

.buttonBase {
      text-transform: none !important;
      color: var(--bg-white) !important;
      font-weight: 400 !important;
      cursor: pointer !important;
      font-size: var(--common-content) !important;
      height: 42px !important;
      &:disabled {
            opacity: .5;
      }
      &:hover {
            box-shadow: 0px 1px 5px var(--border-shadow) !important;
      }
}
.mediumButton{ 
      @extend .buttonBase;
      // Default height – 
      height: 42px;
}
.prescriptionFilterActive{
       // (only used in prescription listing filter so far) -
      @extend .buttonBase;
      background-color: var(--color-lightgrey) !important;
      border-color: var(--color-dimgray) !important;
      color: var(--color-black) !important;
      border-radius: 5px !important;
}
.prescriptionFilterInactive{
      // (only used in prescription listing filter so far) -
      @extend .buttonBase;
      background-color: var(--chrome-light-background) !important;
      border-color: var(--color-dimgray) !important;
      color: var(--color-black) !important;
      border-radius: 5px !important;
}
.widthFitContent {
      @extend .buttonBase;
      width : fit-content !important ;
}
.smallButton{
      @extend .buttonBase;
      // Default height – 
      height: 35px !important;
}
.iconButton{
      min-width: 48px !important;
      height: 48px !important;
      padding:0px !important;
      border-radius: 10px !important;
      color: var(--bg-white) !important;
      cursor: pointer;
      border: none;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;
      &:hover {
        box-shadow:
          rgba(0.4, 0, 0.2, 0.3) 0px 3px 1px -2px, rgba(0.4, 0, 0.2, 0.3) 0px 2px 2px 0px, rgba(0.4, 0, 0.2, 0.3) 0px 1px 5px 0px !important;
      }
}

.outlinedButton {
      @extend .buttonBase;
      color: var(--color-base) !important;
      background-color: var(--bg-white) !important;
      border-radius: 100%;    
}

.primaryButton {
      @extend .buttonBase;
      background-color: var(--color-base) !important;
      box-shadow: 0px 1px 5px var(--border-shadow);
}

.secondaryButton {
      @extend .buttonBase;
      background-color: var(--color-darkgray-500) !important;
}

.destructiveButton {
      @extend .buttonBase;
      background-color: var(--color-red) !important;

}

.greenButton {
      @extend .buttonBase;
      background-color: var(--color-green) !important;
}

.orangeButton {
      // (only used in stop impersonation so far) -
      @extend .smallButton;
      background-color: var(--color-orange) !important;
}

.blueButton {
       // (only used in Order Approval panel header so far) -
       @extend .smallButton;
       background-color: var(--color-base) !important;
}

.blueIconButton{
      @extend .iconButton;
      background-color: var(--color-base) !important;
}

.greenIconButton{
      @extend .iconButton;
      background-color: var(--color-green) !important;
}

.grayIconButton{
      @extend .iconButton;
      background-color: var(--color-grey) !important;
}

.redIconButton {
      @extend .iconButton;
      background-color: var(--color-red) !important;
  }

.blueIconSmallButton{
      @extend .blueIconButton;
      padding:0px !important;
      min-width: 42px !important;
      height: 42px !important;
}

.greenIconSmallButton{
      @extend .greenIconButton;
      padding:0px !important;
      min-width: 42px !important;
      height: 42px !important;
}

.grayIconSmallButton{
      @extend .grayIconButton;
      padding:0px !important;
      min-width: 42px !important;
      height: 42px !important;
}

.redIconSmallButton {
      @extend .redIconButton;
      padding:0px !important;
      min-width: 42px !important;
      height: 42px !important;
  }

.marginRightFive {
      margin-right: 5px !important;
}

.lineHeight16 {
      line-height: 16px !important;
}


@media (max-width: 600px){
      .searchFilterIcon {
            justify-content: flex-start;
      }
}
@media (max-width: 3000px) {
      .mainContainer {
            max-width: 3000px !important;
      }
}